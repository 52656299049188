import Ajax from './ajax';
import Logger from '../common/logger';
// import { toast } from 'react-toastify';
//import i18next from 'i18next';

export const getProductPricingData = async (contractId, productClusterId) => {
    try {
        const resp = await Ajax.get(`api/productpricing/prodprice/${contractId}/${productClusterId}`);
        return resp.data;
    } catch (err) {
        Logger.LogError(`Error getting pricing cache data for contract:${contractId}, prod:${productClusterId}`, '');
        throw err;
    }
};

export const getBundlePricingData = async (contractId, bundleClusterId) => {
    try {
        const resp = await Ajax.get(`api/productpricing/bundleprice/${contractId}/${bundleClusterId}`);
        return resp.data;
    } catch (err) {
        Logger.LogError(`Error getting pricing cache data for contract:${contractId}, bundle:${bundleClusterId}`, '');
        throw err;
    }
};

export const getProductPricingDataBatch = async (contractId, productClusterIds) => {
    try {
        const resp = await Ajax.post(`api/productpricing/prodpricebatch/${contractId}`, productClusterIds);
        return resp.data;
    } catch (err) {
        Logger.LogError(
            `Error getting pricing cache data for contract:${contractId}, prods:${productClusterIds.join(',')}`,
            ''
        );
        throw err;
    }
};

export const getBundlePricingDataBatch = async (contractId, bundleClusterIds) => {
    try {
        const resp = await Ajax.post(`api/productpricing/bundlepricebatch/${contractId}`, bundleClusterIds);
        return resp.data;
    } catch (err) {
        Logger.LogError(
            `Error getting pricing cache data for contract:${contractId}, bundles:${bundleClusterIds.join(',')}`,
            ''
        );
        throw err;
    }
};
