import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from '@material-ui/core/InputAdornment';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { formatCurrency, parseDecimal } from '../common/common';
import ContractPrice from '../domain/contractprice';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import DeleteButton from '../common/deletebutton';
import { round } from 'mathjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    withBottomBorder: {
        borderBottom: '1px solid lightgray',
        paddingBottom: '0.5em',
    },
    withExtraPadding: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    withTopMargin: {
        marginTop: '10px',
    },
    highlight: {
        fontWeight: 'bold',
    },
    button: {
        padding: '0.5em',
    },
    productMarginWarning: {
        color: 'darkred',
    },
    productMarginOk: {
        color: 'green',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    bundleProductDetails: {
        fontSize: '0.75em',
    },
}));

export default function ContractPricesProduct(props) {
    const row = props.priceRow;
    const edit = props.edit;
    const highlight = props.highlight;
    const pricingRequest = props.pricingRequest;
    const classes = useStyles();
    const [saving, setSaving] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {}, []);

    const productRowClasses = clsx(classes.withBottomBorder, {
        [classes.withExtraPadding]: edit,
        [classes.highlight]: edit || highlight,
    });

    const onSelectRow = () => {
        props.selectRow();
    };

    const editRow = () => {
        props.editRow();
    };

    const handleDiscountChange = (e) => {
        let value = e.target.value.toString().replace(',', '.');
        if (!/^-?\d*[.]?\d{0,4}$/.test(value)) {
            return;
        } else if (!(value > 100)) {
            const newCp = new ContractPrice(row);
            newCp.discount = value; //round(value, 3);
            props.onChange(newCp);
        }
    };

    const sanitizeDiscountValue = () => {
        const newCp = new ContractPrice(row);
        newCp.discount = round(parseDecimal(row.discount), 3);
        props.onChange(newCp);
    };

    const handlePriceChange = (e) => {
        const value = e.target.value.toString().replace(',', '.');
        const newCp = new ContractPrice(row);
        if (!/^-?\d*[.]?\d{0,6}$/.test(value)) {
            return;
        } else {
            newCp.price = value; //round(value, 3);
            props.onChange(newCp);
        }
    };

    const sanitizePriceValue = () => {
        const newCp = new ContractPrice(row);
        if (newCp.price === '') {
            newCp.price = null;
        } else {
            newCp.price = round(newCp.price, 3);
        }
        props.onChange(newCp);
    };

    const onDiscountPriceSwap = () => {
        const newCp = new ContractPrice(row);
        if (newCp.discount === null) {
            newCp.discount = newCp.calculateDiscountPercent();
            newCp.price = null;
        } else {
            newCp.price = newCp.calculateDiscountPrice();
            newCp.discount = null;
        }
        props.onChange(newCp);
    };

    const save = async () => {
        setSaving(true);
        await props.save(row);
        setSaving(false);
        props.cancel();
    };

    const cancel = () => {
        props.cancel();
    };

    const formatDiscountPercent = (percent) => {
        return percent < 0 ? `+${-1 * percent}%` : `-${percent}%`;
    };

    const isSaveDisabled = () => {
        if (saving) return true;
        if (row.discount === null && (row.price === null || row.price.length === 0)) return true;
        if (row.price === null && (row.discount === null || row.discount.length === 0)) return true;
        return false;
    };

    const getMarginClass = (marginPercentage) => {
        return marginPercentage > 0 ? classes.productMarginOk : classes.productMarginWarning;
    };

    const ContractPriceMargingDom = ({ prefix }) => {
        prefix = prefix ? prefix : '';
        return (
            <div>
                {row.marginEur() !== -1 && (
                    <span className={getMarginClass(row.marginPercentage())}>
                        {`${prefix}${formatCurrency(row.marginEur())} (${row.marginPercentage()}%)`}
                    </span>
                )}
                {row.marginEur() === -1 && <span className={classes.productMarginWarning}>{`${prefix}*`}</span>}
            </div>
        );
    };

    return (
        <Grid name="product-row-container" item container className={productRowClasses} onClick={onSelectRow}>
            <Grid item xs={12} sm={4}>
                {row.product ? row.product.productName : row.productBundle.name}
                {row.productBundle && (
                    <span className={classes.bundleProductDetails}>
                        <br />
                        {row.productBundle.products.map((p) => (
                            <div key={p.product.id}>
                                {p.productCount} x {p.product.productName}
                            </div>
                        ))}
                    </span>
                )}
            </Grid>
            <Grid item xs={6} sm={2}>
                {formatCurrency(row.product ? row.product.priceWithoutVat : row.productBundle.priceWithoutVat())}
            </Grid>
            <Grid item xs={12} sm={6}>
                {!edit && (
                    <Grid container>
                        <Grid item xs={8}>
                            <span>
                                {!row.price && !row.discount && <span>{t('product.noContractPrice')}</span>}
                                {!!row.price && (
                                    <Grid item container>
                                        <Grid item xs={4}>
                                            <strong>{formatCurrency(row.price, 3)}</strong>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {formatDiscountPercent(row.calculateDiscountPercent())}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.sectionDesktop}>
                                                <ContractPriceMargingDom />
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}
                                {!!row.discount && (
                                    <Grid item container>
                                        <Grid item xs={4}>
                                            {formatCurrency(row.calculateDiscountPrice(), 3)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <strong>{formatDiscountPercent(row.discount)}</strong>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className={classes.sectionDesktop}>
                                                <ContractPriceMargingDom />
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            {row.inUse && !pricingRequest && <span>{t('product.inUse')}</span>}
                            {!row.inUse && !pricingRequest && <span>{t('product.notInUse')}</span>}
                        </Grid>
                        <Grid item xs={12} className={classes.sectionMobile}>
                            <ContractPriceMargingDom prefix={t('general.margin') + ': '} />
                        </Grid>
                    </Grid>
                )}
                {edit && row.discount !== null && (
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <span>
                                {formatCurrency(row.calculateDiscountPrice(), 3)}
                                /kpl
                            </span>
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.swapIconCol}>
                            <SwapHorizIcon name="swapIcon" onClick={onDiscountPriceSwap} />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField
                                name="filterDiscount"
                                value={row.discount ? row.discount : ''}
                                onChange={handleDiscountChange}
                                onBlur={sanitizeDiscountValue}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ContractPriceMargingDom prefix={t('general.margin') + ': '} />
                        </Grid>
                    </Grid>
                )}
                {edit && row.discount === null && (
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <TextField
                                name="filterPrice"
                                value={row.price ? row.price : ''}
                                onChange={handlePriceChange}
                                onBlur={sanitizePriceValue}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€/{t('pcs')}</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.swapIconCol}>
                            <SwapHorizIcon name="swapIcon" onClick={onDiscountPriceSwap} />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <span>{row.calculateDiscountPercent()} %</span>
                        </Grid>
                        <Grid item xs={12}>
                            <ContractPriceMargingDom prefix={t('general.margin') + ': '} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {highlight && !edit && (
                <Grid item container>
                    <Grid item className={classes.button}>
                        <Button
                            variant="contained"
                            name="edit-row"
                            color="primary"
                            onClick={editRow}
                            startIcon={<EditIcon />}>
                            {t('buttons.edit')}
                        </Button>
                    </Grid>
                    {!pricingRequest && (
                        <Grid item className={classes.button}>
                            <DeleteButton onSubmit={() => props.delete(row)} disabled={false} deleting={false} />
                        </Grid>
                    )}
                </Grid>
            )}
            {edit && (
                <Grid container spacing={1} className={classes.withTopMargin}>
                    <Grid item>
                        <Button
                            name="save-row"
                            disabled={isSaveDisabled()}
                            variant="contained"
                            color="primary"
                            onClick={save}
                            startIcon={saving ? <CircularProgress color={'secondary'} size={'1rem'} /> : <SaveIcon />}>
                            {t('buttons.save')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            name="cancel"
                            disabled={saving}
                            variant="contained"
                            color="secondary"
                            onClick={cancel}
                            startIcon={<CancelIcon />}>
                            {t('buttons.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

ContractPricesProduct.propTypes = {
    priceRow: PropTypes.object.isRequired,
};
