import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import { Alert } from '@material-ui/lab';
import NotesView, { TypeLocation } from '../customer/notesview';
import {
    getLocationFull as getLocationFullSales,
    saveLocation,
    getLocationXlsx,
    updatePortfolio,
    copyToNewLocation,
} from '../modules/locationdata';
import { getLocationFull as getLocationFullCustomer } from '../modules/customerlocationdata';
import { addFilterToOrder as addFilterToOrderCustomer } from '../modules/customerorderdata';
import { addMachineToOrder as addMachineToOrderCustomer } from '../modules/customerorderdata';
import { addFilterToOrder as addFilterToOrderSales } from '../modules/orderdata';
import { addMachineToOrder as addMachineToOrderSales } from '../modules/orderdata';
import { newOrderFromLocations as newOrderFromLocationsCustomer } from '../modules/customerorderdata';
import { newOrderFromLocations as newOrderFromLocationsSales } from '../modules/orderdata';
import { sendFeedback } from '../modules/feedbackdata';
import LocationViewBasicInfo from './locationviewbasicinfo';
import LocationViewMachineInfo from './locationviewmachineinfo';
import PartyView from '../common/partyview';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ChangePortfolioDialog from './changeportfoliodialog';
import Location from '../domain/location';
import Logger from '../common/logger';
import { some } from 'lodash';
import { IsProductStandard } from '../common/productdataparse';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ConfirmationDialog from '../common/confirmationdialog';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { getBundlePricingData, getProductPricingData } from '../modules/pricingcachedata';

const delay = async (ms) => {
    await new Promise((r) => setTimeout(r, ms));
};

export default function LocationView(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerUi = process.env.REACT_APP_TARGET === 'customerui';
    const [expandOrders, setExpandOrders] = useState(false);
    const locationId = parseInt(props.match.params.locationId, 10);
    const locationsByIdCust = useSelector((state) => state.customerlocationdata.locationsById);
    const locationsByIdVado = useSelector((state) => state.locationdata.locationsById);
    const locationFull = customerUi ? locationsByIdCust[locationId] : locationsByIdVado[locationId];
    const products = useSelector((state) => state.productdata.filterProducts);
    const productBundles = useSelector((state) => state.productdata.productBundles);
    const [containsDraftProducts, setContainsDraftProducts] = useState(false);
    const [changePortfolioOpen, setChangePortfolioOpen] = useState(false);
    const [containsNonStandardProducts, setContainsNonStandardProducts] = useState(false);
    const [reff] = useState(React.createRef());
    const [blobUrl, setBlobUrl] = useState('');
    const [loadingXlsx, setLoadingXlsx] = useState(false);
    const allPortfolios = useSelector((state) => state.portfoliodata.portfolioDescriptions);
    const showcaseMode = useSelector((state) => state.appstate.showcaseMode);
    const features = useSelector((state) => state.settingsdata.features);
    const energyCalculatorInUse = features != null ? features.energyCalculatorInUse : false;
    const [processingPortfolioChange, setProcessingPortfolioChange] = useState(false);
    const [copyLocationConfirm, setCopyLocationConfirm] = useState(false);
    const contracts = useSelector((state) => state.contractdata.contractsById);

    useEffect(() => {
        if (!locationFull) {
            if (customerUi) dispatch(getLocationFullCustomer(locationId));
            else dispatch(getLocationFullSales(locationId));
        } else if (!customerUi) {
            setContainsDraftProducts(locationFull.containsDraftProducts());
        }
    }, [locationFull, customerUi, dispatch, locationId]);

    useEffect(() => {
        if (locationFull && !customerUi) {
            setContainsNonStandardProducts(
                some(locationFull.allFilters(), (filter) => {
                    return filter.product && !IsProductStandard(filter.product.productName);
                })
            );
        }
    }, [locationFull, customerUi]);

    const loadLocationXlsx = async (e) => {
        if (blobUrl) {
            e.stopPropagation();
            await delay(100);
            setBlobUrl('');
            setLoadingXlsx(false);
            return;
        }
        setLoadingXlsx(true);
        const xlsx = await getLocationXlsx(locationId);

        const buff = Buffer.from(xlsx, 'base64');
        const blob = new Blob([buff.buffer], {
            type: 'application/vnd.openxmlformats',
        });
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);

        setTimeout(() => {
            reff.current.click();
        }, 10);
    };

    const getOrderStatus = () => {
        if (locationFull != null && locationFull.latestOrders != null && locationFull.latestOrders.length > 0) {
            return t('location.orderStatusWithCountAndDate', {
                orderCount: locationFull.latestOrders.length,
                latestOrderDate: moment(locationFull.latestOrders[0].orderSent).format('DD.MM.YYYY'),
            });
        }
        return t('location.orderStatusNoOrders');
    };

    const onEditItem = () => {
        props.history.push('/locations/edit/' + locationId);
    };

    const onDownloadItem = () => {
        window.open('/locations/print/' + locationId + '?sidebar=false');
    };

    const onAddToBasket = () => {
        let ids = [locationId];
        if (customerUi) dispatch(newOrderFromLocationsCustomer(ids));
        else dispatch(newOrderFromLocationsSales(ids));
    };

    const onSurveyLinkClicked = () => {
        props.history.push('/survey/' + locationFull.surveyId);
    };

    const close = () => {
        props.history.goBack();
    };

    const toggleOrders = () => {
        setExpandOrders(!expandOrders);
    };

    const onOpenOrder = (orderNumber) => {
        props.history.push('/ordernumber/' + orderNumber);
    };

    const onSendFeedback = (feedback) => {
        dispatch(sendFeedback(feedback));
    };

    const onAddFilterToCart = (filterId, machineId) => {
        if (customerUi) dispatch(addFilterToOrderCustomer(locationId, machineId, filterId));
        else dispatch(addFilterToOrderSales(locationId, machineId, filterId));
    };

    const onAddMachineToCart = (machineId) => {
        if (customerUi) dispatch(addMachineToOrderCustomer(locationId, machineId));
        else dispatch(addMachineToOrderSales(locationId, machineId));
    };

    const onChangePortfolio = () => {
        setChangePortfolioOpen(true);
    };
    const cancelChangePortfolio = () => {
        setChangePortfolioOpen(false);
    };
    const changePortfolio = (newPortfolioId) => {
        setProcessingPortfolioChange(true);
        dispatch(updatePortfolio(locationId, newPortfolioId, locationFull.customer.id))
            .then(() => {
                toast.info(t('location.toast.locationMovedToOtherPortfolio'), { autoClose: 2000 });
                setProcessingPortfolioChange(false);
                setChangePortfolioOpen(false);
                props.history.goBack();
            })
            .catch((err) => {
                setProcessingPortfolioChange(false);
                setChangePortfolioOpen(false);
                toast.error(t('location.toast.errorInMovingToOtherPortfolio'), { autoClose: 5000 });
                Logger.LogError(`locationview.jsx; failed to change location portfolio to ${newPortfolioId}`, '');
            });
    };

    const replaceProduct = async (oldProdId, newProductId, filterId, machineId, replaceType) => {
        if (newProductId && oldProdId) {
            const newLoc = new Location(locationFull);
            const newProd = products.find((p) => p.id === newProductId);
            const newBundle = productBundles.find((b) => b.id === newProductId);

            if (!newProd && !newBundle) {
                Logger.LogError(`locationview.jsx; cannot find new product, id:${newProductId}`, '');
                throw new Error('error');
            }
            const pricingData = newProd
                ? await getProductPricingData(
                      locationFull.contractNumber ? locationFull.contractNumber.id : 0,
                      newProd.storageId
                  )
                : await getBundlePricingData(
                      locationFull.contractNumber ? locationFull.contractNumber.id : 0,
                      newBundle.clusterId
                  );

            newLoc.replaceProduct(
                oldProdId,
                newProd ? newProd : newBundle,
                filterId,
                machineId,
                replaceType,
                pricingData
            );
            saveLocation(newLoc)(dispatch);
        }
    };

    const openEnergyReport = () => {
        props.history.push(`/locations/${locationId}/energy`);
    };

    const confirmCopyLocation = async () => {
        var resp = await copyToNewLocation(locationFull.id)(dispatch);
        setCopyLocationConfirm(false);
        if (resp) {
            toast.info(t('location.locationCopyDone', { copyName: locationFull.name + ' (kopio)' }), {
                autoClose: 2000,
            });
        } else {
            toast.error(t('general.error'), { autoClose: 5000 });
        }
    };

    const latestOrder = getOrderStatus();
    const disableButtons = !locationFull;

    return (
        <Container maxWidth={'lg'}>
            <Grid item xs={12}>
                {locationFull != null && (
                    <h2>
                        <Button onClick={close}>
                            <i className="fas fa-chevron-left fa-2x"></i>
                            &nbsp;&nbsp;
                        </Button>
                        {locationFull.name}
                    </h2>
                )}
                {!locationFull && <Spinner padding="20px" title={t('general.loading')} />}
                {locationFull && (
                    <Grid item>
                        {locationFull.usesRemovedProducts && (
                            <div style={{ padding: '20px' }}>
                                <Alert severity="error">{t('location.alerts.containsDiscontinuedProducts')}</Alert>
                            </div>
                        )}

                        {containsNonStandardProducts && (
                            <div style={{ padding: '20px' }}>
                                <Alert severity="warning">{t('location.alerts.containsNonStandardProducts')}</Alert>
                            </div>
                        )}

                        <LocationViewBasicInfo
                            contracts={contracts}
                            location={locationFull}
                            customerUi={customerUi}
                            history={props.history}
                            sendFeedback={onSendFeedback}
                            viewOnly={false}
                            showcaseMode={showcaseMode}
                        />

                        {!customerUi && locationFull.notes && <NotesView id={locationId} type={TypeLocation} />}
                        <Grid item xs={12}>
                            <hr style={{ marginTop: '10px' }} />
                        </Grid>

                        {!customerUi && locationFull.partyRelations && (
                            <div style={{ marginBottom: '20px' }}>
                                <h5>{t('general.contactInformation')}</h5>
                                <PartyView showControls={false} parties={locationFull.partyRelations} />
                            </div>
                        )}

                        <LocationViewMachineInfo
                            machines={locationFull.machines}
                            addFilterToCart={onAddFilterToCart}
                            addMachineToCart={onAddMachineToCart}
                            viewOnly={false}
                            products={products}
                            customerUi={customerUi}
                            containsDrafts={containsDraftProducts}
                            replaceProduct={replaceProduct}
                            contractPriceInUse={locationFull.contractNumber && locationFull.contractNumber.id !== 1}
                        />

                        <Accordion expanded={expandOrders}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                onClick={toggleOrders}
                                name="div-toggle-order-history">
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <strong>{t('location.orderHistory')}</strong>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {latestOrder}
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container styles={{ width: '100%' }}>
                                    {locationFull.latestOrders &&
                                        locationFull.latestOrders.map((o) => (
                                            <Grid item xs={12} key={o.id}>
                                                <Button onClick={() => onOpenOrder(o.orderNumber)} name="a-open-order">
                                                    {t('location.orderDetail', {
                                                        orderDate: moment(o.orderSent).format('DD.MM.YYYY HH:mm'),
                                                        ordererName: o.ordererName,
                                                        orderSender: o.orderSender,
                                                    })}
                                                </Button>
                                            </Grid>
                                        ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}
            </Grid>
            <br />
            <br />
            <Footer>
                {customerUi === false && (
                    <Grid item>
                        <Button
                            ref={reff}
                            variant="contained"
                            color="primary"
                            name="btn-load-location-xsls"
                            onClick={loadLocationXlsx}
                            href={blobUrl.length > 0 ? blobUrl : null}
                            download={blobUrl.length > 0 ? `${locationFull.name}_${moment().format()}.xlsx` : null}
                            startIcon={
                                loadingXlsx ? <CircularProgress color={'secondary'} size={'1rem'} /> : <DownloadIcon />
                            }>
                            Excel
                        </Button>
                    </Grid>
                )}
                {customerUi === false && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="btn-download"
                            onClick={onDownloadItem}
                            disabled={disableButtons}
                            startIcon={<DownloadIcon />}>
                            PDF
                        </Button>
                    </Grid>
                )}
                {customerUi === false && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="btn-edit"
                            onClick={onEditItem}
                            disabled={disableButtons}
                            startIcon={<EditIcon />}>
                            {t('buttons.edit')}
                        </Button>
                    </Grid>
                )}
                {customerUi === false && (
                    <Grid item>
                        <Button
                            name="btn-change-portfolio"
                            variant="contained"
                            color="primary"
                            onClick={onChangePortfolio}
                            disabled={false}
                            startIcon={<SwapHorizIcon />}>
                            {t('general.portfolio')}
                        </Button>
                    </Grid>
                )}

                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-add-to-cart"
                        onClick={onAddToBasket}
                        disabled={
                            containsDraftProducts ||
                            disableButtons ||
                            (locationFull != null && locationFull.usesRemovedProducts)
                        }
                        title={
                            containsDraftProducts ||
                            disableButtons ||
                            (locationFull != null && locationFull.usesRemovedProducts)
                                ? t('location.hints.notAbleToAddToCart')
                                : null
                        }
                        startIcon={<AddShoppingCartIcon />}>
                        {t('buttons.addToCart')}
                    </Button>
                </Grid>

                {customerUi === false && (
                    <Grid item>
                        <Button
                            name="btn-copy-location"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setCopyLocationConfirm(true);
                            }}
                            disabled={false}
                            startIcon={<CopyAllIcon />}>
                            {t('general.copy')}
                        </Button>
                    </Grid>
                )}
                {customerUi === false && locationFull && !!locationFull.surveyId && (
                    <Grid item>
                        <Button
                            name="btn-survey-link"
                            variant="contained"
                            color="primary"
                            onClick={onSurveyLinkClicked}
                            disabled={false}
                            startIcon={<AttachmentIcon />}>
                            {t('survey.surveyReport')}
                        </Button>
                    </Grid>
                )}

                <Grid item>
                    <Button variant="contained" color="secondary" name="btn-close" onClick={close}>
                        {t('buttons.close')}
                    </Button>
                </Grid>
                {customerUi === false && energyCalculatorInUse && (
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Energialaskelma</FormLabel>
                            <Checkbox
                                checked={false}
                                onChange={openEnergyReport}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </FormControl>
                    </Grid>
                )}
            </Footer>

            <FooterPadding />
            <ConfirmationDialog
                open={copyLocationConfirm}
                cancel={() => {
                    setCopyLocationConfirm(false);
                }}
                confirm={confirmCopyLocation}>
                {t('location.confirmMakeCopy', { copyName: locationFull ? locationFull.name + ' (kopio)' : '' })}
            </ConfirmationDialog>

            {locationFull && (
                <ChangePortfolioDialog
                    open={changePortfolioOpen}
                    cancel={cancelChangePortfolio}
                    changePortfolio={changePortfolio}
                    currentPortfolioId={locationFull.portfolio.id}
                    portfolios={allPortfolios}
                    processing={processingPortfolioChange}
                />
            )}
        </Container>
    );
}
