import { flatten, uniq } from 'lodash';

export const returnAllAndAllowedPalletCodesInOrders = (orderInScope, selectedOrders, allOrders) => {
    const filteredLocations = selectedOrders.filter(
        (o) =>
            // o.deliveryAddress.name === orderInScope.deliveryAddress.name &&
            o.deliveryAddress.streetAddress === orderInScope.deliveryAddress.streetAddress &&
            o.deliveryAddress.zipCode === orderInScope.deliveryAddress.zipCode &&
            o.deliveryAddress.city === orderInScope.deliveryAddress.city
    );
    const allowedPalletCodesAndLocations = flatten(
        filteredLocations.map((o) => {
            return uniq(o.orderedFilters.map((fo) => fo.palletInformation)).map((c) => {
                return { name: o.location ? o.location.name : o.deliveryRecipient, code: c };
            });
        })
    );
    const allowedPalletCodes = uniq(
        flatten(
            filteredLocations.map((o) => {
                return o.orderedFilters;
            })
        ).map((fo) => fo.palletInformation)
    );

    const potentialCodes = allowedPalletCodes.map((c) => {
        return {
            code: c,
            locations: allowedPalletCodesAndLocations.filter((cl) => cl.code === c).map((loc) => loc.name),
        };
    });

    const allUsedPalletCodes = uniq(
        flatten(
            allOrders.map((o) => {
                return o.orderedFilters;
            })
        ).map((fo) => fo.palletInformation)
    );

    return [allUsedPalletCodes, potentialCodes];
};

export const getPalletInfosUsedInOrder = (order) => {
    if (order && order.orderedFilters) {
        return uniq(order.orderedFilters.map((fo) => fo.palletInformation));
    }
    return [];
};

const getFreshPalletCode = (allUsedCodes, availablePalletCodes) => {
    for (const c of availablePalletCodes) {
        if (!allUsedCodes.includes(c)) {
            return { code: c, locations: [] };
        }
    }
    throw new Error('Could not allocate pallet code');
};

// Basic logic
// 1) Allow to select any of the given codes (allowedPalletCodes)
// 2) But: Do not allow to select already used code unless some of the filters in the order use some other pallet. In which case the order would be
//    harmonized to use a single pallet code
// 3) And: If the pallet code that order is using is being used by some other order already, offer a new/fresh pallet code for use
// 4) And: If the order is using multiple pallet codes and all of them are already used by other orders, offer a new/fresh pallet code for use
//
export const determineAllowedPalletCodesInSelection = (
    orderInScope,
    fosInScope,
    allowedCodes,
    allUsedCodes,
    availablePalletCodes
) => {
    const usedCodesOrder = getPalletInfosUsedInOrder(orderInScope);
    if (fosInScope && fosInScope.length > 0) {
        const allSelected = fosInScope.length === orderInScope.orderedFilters.length;
        const allowed = allowedCodes;
        if (allSelected) {
            let addFresh = true;
            for (const c of usedCodesOrder) {
                if (allowedCodes.find((cod) => cod.code === c).locations.length === 1) {
                    addFresh = false;
                    break;
                }
            }
            if (addFresh) {
                return [...allowed, getFreshPalletCode(allUsedCodes, availablePalletCodes)];
            }
            return allowed;
        } else {
            return [...allowed, getFreshPalletCode(allUsedCodes, availablePalletCodes)];
        }
    } else {
        if (usedCodesOrder && usedCodesOrder.length > 1) {
            let addFresh = true;
            for (const c of usedCodesOrder) {
                if (allowedCodes.find((cod) => cod.code === c).locations.length === 1) {
                    addFresh = false;
                    break;
                }
            }
            if (addFresh) {
                return [...allowedCodes, getFreshPalletCode(allUsedCodes, availablePalletCodes)];
            }
            return allowedCodes;
        } else if (usedCodesOrder && usedCodesOrder.length === 1) {
            const allowed = allowedCodes.filter((c) => c.code !== usedCodesOrder[0]);
            const codeInUse = allowedCodes.find((c) => c.code === usedCodesOrder[0]);

            if (codeInUse.locations.length > 1) {
                return [...allowed, getFreshPalletCode(allUsedCodes, availablePalletCodes)];
            }
            return allowed;
        }
    }
};
