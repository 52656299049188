import FilterProduct from './filterproduct';
import { round } from 'mathjs';
import { ProductBundle } from './productbundle';

class Filter {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            if (json.product) this.product = new FilterProduct(json.product);
            else if (json.bundle) this.bundle = new ProductBundle(json.bundle);
            this.count = json.count;
            this.discount = json.discount;
            this.price = json.price;
            this.additionalInfo = json.additionalInfo || '';
            this.viewOrder = json.viewOrder;
            this.originalProductName = json.originalProductName;
            this.contractPricesInUse = json.contractPricesInUse;
            this.unitNetPrice = json.unitNetPrice;
        } else {
            this.id = 0;
            this.product = new FilterProduct();
            this.count = 0;
            this.discount = 0;
            this.price = null;
            this.additionalInfo = '';
            this.viewOrder = 1;
            this.unitNetPrice = 0;
        }
    }

    cpNotInUsePriceRounded() {
        return this.price % 1 > 0 ? round(this.price, 2) : this.price;
    }

    cpNotInUseDiscountRounded(precision) {
        if (!!!precision) precision = 4;
        return this.discount % 1 > 0 ? round(this.discount, precision) : this.discount;
    }

    cpNotInUseCalculateDiscountPricePerPcs() {
        const price = round(this.catalogValuePerPcs() * ((100 - this.discount) / 100), 2);
        if (isNaN(price)) return 0;
        return price;
    }

    cpNotInUseCalculateDiscountPercent() {
        const discount = round(100 - (this.price / this.catalogValuePerPcs()) * 100, 4);
        if (isNaN(discount)) return 0;
        return discount;
    }

    getFilterTotalValue() {
        //returns filter price multiplicated by count
        if (this.contractPricesInUse) {
            return this.unitNetPrice * this.count;
        }
        return this.discount === null
            ? this.price * this.count
            : ((100 - this.discount) / 100) * this.catalogValuePerPcs() * this.count;
    }

    //return filter price, if price set, return rounded price, if discount set, calculates rounded price
    getFilterPriceRounded() {
        if (this.contractPricesInUse) {
            round(this.unitNetPrice, 2);
        }
        return this.discount == null
            ? round(this.price, 2)
            : round(((100 - this.discount) / 100) * this.catalogValuePerPcs(), 2);
    }

    marginEur() {
        if (this.productionPrice() > 0) {
            return this.getFilterTotalValue() - this.productionPrice() * this.count;
        } else {
            return -1;
        }
    }

    marginPercentage() {
        if (this.productionPrice() > 0) {
            return ((1 - (this.productionPrice() * this.count) / this.getFilterTotalValue()) * 100).toFixed(2);
        } else {
            return -1;
        }
    }

    catalogValue() {
        if (this.product) {
            return this.product.priceWithoutVat * this.count;
        } else if (this.bundle) {
            return this.bundle.priceWithoutVat() * this.count;
        }
    }

    catalogValuePerPcs() {
        if (this.product) {
            return this.product.priceWithoutVat;
        } else if (this.bundle) {
            return this.bundle.priceWithoutVat();
        }
    }

    productionPrice() {
        if (this.product) {
            return this.product.productionPrice;
        } else {
            return this.bundle.productionPrice();
        }
    }

    expired() {
        if (this.product) {
            return this.product.expired;
        } else {
            return this.bundle.isExpired();
        }
    }

    fabricSurfaceArea() {
        if (this.product) {
            return this.product.fabricSurfaceArea;
        } else {
            return this.bundle.fabricSurfaceArea();
        }
    }
}

export default Filter;
